
.cont {
   
     display: flex;
    justify-content: center;
    position: fixed;
    width: 90%;
    height: 90%;
    gap: 3%;
}
.leftCont{
    display: flex;
    width: 60%;
    height: 100%;
    flex-direction: column;
 
}
.rightCont{
    display: flex;
    width: 40%;
    height: 100%;
    flex-direction: row;
    gap: 5%;

}
.subrightCont{
    display: flex;
    width: 50%;
    height: 100%;
    flex-direction: column;
    
 
}
.subrightCard{
    width: 100%;
    height: 33.33%;
    display: flex;
    align-items: center;
    }
.leftbigCont{
    display: flex;
    width: 100%;
    height: 80%;
    align-items: center;
    gap: 5%;
}
.leftsmallCont{
    display: flex;
    width: 100%;
    height: 40%;
    flex-direction: row;
    align-items: center;
    gap: 5%;
}
.leftsamll{
width: 33.33%;
height: 100%;
 
align-items: center;
  
  display: flex;
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(to bottom, #eeeeee17, #53535380); */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 1;

}

.Carousel {
    height: 75%;
    border: 1px solid #000;  /* Adjust the width and color as needed */
    border-radius: 25px;
    background-color: #EBF7F9;
    width: 100%;
    box-shadow: 9px 9px 0px black;
    border: 1px black solid;
    border-radius: 25px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    display: flex;
}

  .BigCarousel {
    height: 88%;
    width: 100%;
    border: 1px solid #000;  /* Adjust the width and color as needed */
    border-radius: 25px;
    background-color: #DFEABE;
    width: 100%;
    box-shadow: 9px 9px 0px black;
    border: 1px black solid;
    border-radius: 25px;
    display: flex;
  }
   
  .StartEndDate {
    color: black;
    font-size: 32px;
    font-family: 'Quicksand';
    font-weight: 700;
    word-wrap: break-word;
}
.DateFrame {
  
   
    padding: 8px;
    background: #FDE5FC;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    width: 100%;
    align-self: flex-end;
    top: 50%;
}
.BigDateFrame{
       
    padding: 8px;
    background: #FDE5FC;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    width: 100%;
    
    align-self: flex-end;
    top: 50%;

}
.SlideCont{
    position: relative;
    display: flex;
    flex-direction: column;
 
    width: 100%;
    height: 42%;
}
.firstSlideCont{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 25%;
}
.labelsCont{
width: 50%;
height: 100%;
}
.status{
    height: 100%;
 background-color: aqua;
}
.element {
    color: black;
  
    font-size: 64px;
  
    font-family: Noto Sans Arabic;
  
    font-weight: 600;
  
    line-height: 50;
  
    overflow-wrap: break-word;
 
  }
  .desCont h1{
    color: var(--Black-1000, #000);
    text-align: right;
    font-family: "Noto Sans Arabic";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 138.889% */
  }
  .status{
    display: flex;
    padding: var(--Radius-1, 8px) var(--Radius-2, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--Radius-1, 8px);
    border-radius: 69px;
    background: #FFE0BB;
    width: 100%;  
}
  .status h1{color: var(--Black-1000, #000);
    font-family: "Noto Sans Arabic";
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 138.889% */}

    .depart{
        display: flex;
        padding: var(--Radius-1, 8px) var(--Radius-2, 16px);
        justify-content: center;
        align-items: center;
        gap: var(--Radius-1, 8px);
        border-radius: 69px;
        background: #DFEABE;
        width: 100%;
      }

      .depart h1{color: var(--Black-1000, #000);
        font-family: "Noto Sans Arabic";
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px; /* 138.889% */}
.title{
    width: 40%;
}
        .title h1{
            color: var(--Black-1000, #000);
            text-align: right;
            font-family: "Noto Sans Arabic";
            font-size: 40px;
            font-style: normal;
            font-weight: 300;
            line-height: 50px; /* 125% */
        }

.name h1{
    color: var(--Black-1000, #000);
text-align: right;
font-family: "Noto Sans Arabic";
font-size: 48px;
font-style: normal;
font-weight: 400;
line-height: 50px; /* 104.167% */
}
 .name{
     display: flex;
     flex-direction: row;
     width: 40%;
  align-self: end;
  justify-content: end;
 }
 .name img{
     width: 5%;
 }