html,
body {
  height: auto;
  margin: 0;
}

body {

  font-family: Arial, sans-serif;
  /* Optional: Set a default font family */
  color: rgb(255, 255, 255);
  background: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255) !important;
}

 
.app {
  height: auto;
}