.sliderCont {
  width: 100%;
  height: 100%;
  margin: auto;

}
.Cont{
  display: flex;
  flex-direction: column;
 
  height: 100%;
  justify-content: space-between;
}
.SubCont{
  display: flex;
  flex-direction: column;
  padding: 70px 50px 0px 50px;
}
.avatarname{
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 3%;
}
.name{
  display: flex;
  align-items: center;
}
.name h1{
  color: var(--Black-1000, #000);
  text-align: right;
  font-family: "Noto Sans Arabic";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 104.167% */
}
.Bigname{
  display: flex;
  align-items: center;
}
.Bigname h1{
  color: var(--Black-1000, #000);
  text-align: right;
  font-family: "Noto Sans Arabic";
  font-size: 128px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 39.063% */
}
.profileImg  {
  width: 118px;
  height: 118px;
}

.BigprofileImg{
  width: 274px;
height: 274px;
}
.BigprofileImg img{
  width:100%;
height: 100%;
}
.titlelabelsCont{
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
  gap: 5%;
}
.title{
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: end;
}
.title h1{
  color: var(--Black-1000, #000);
  text-align: right;
  font-family: "Noto Sans Arabic";
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px; /* 125% */
}
.Bigtitle{
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: end;
}
.Bigtitle h1{
  color: var(--Black-1000, #000);
  text-align: right;
  font-family: "Noto Sans Arabic";
  font-size: 96px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px; /* 52.083% */
}
.labelsCont{
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap:5%;
}
.status{
display: flex;
width: 129px;
padding: var(--Radius-1, 8px) var(--Radius-2, 16px);
justify-content: center;
align-items: center;
gap: var(--Radius-1, 8px);
width: 154px;
height: 66;

border-radius: 70px;
justify-content: center;
align-items: center;
gap: 8;
display: 'inline-flex';
}
.status h1{
  color: var(--Black-1000, #000);
font-family: "Noto Sans Arabic";
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: 50px; /* 138.889% */
}

.depart{
  display: flex;
width: 129px;
padding: var(--Radius-1, 8px) var(--Radius-2, 16px);
justify-content: center;
align-items: center;
gap: var(--Radius-1, 8px);
width: 129;
height: 66;
background: #DFEABE;
border-radius: 70px;
justify-content: center;
align-items: center;
gap: 8;
display: 'inline-flex';
}
.depart h1{
  color: var(--Black-1000, #000);
font-family: "Noto Sans Arabic";
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: 50px; /* 138.889% */
}
.Bigstatus{
  display: flex;
  width: 391px;
  height: 128px;
  padding: var(--Radius-1, 8px) var(--Radius-2, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--Radius-1, 8px);
  width: 129;
  height: 66;
  background: #FFE0BB;
  border-radius: 70px;
  justify-content: center;
  align-items: center;
  gap: 8;
  display: 'inline-flex';
  }
  .Bigstatus h1{
    color: var(--Black-1000, #000);
    font-family: "Noto Sans Arabic";
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px; /* 78.125% */
  }
  .Bigdepart{
    display: flex;
    width: 391px;
    height: 128px;
  padding: var(--Radius-1, 8px) var(--Radius-2, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--Radius-1, 8px);
  width: 129;
  height: 66;
  background: #DFEABE;
  border-radius: 70px;
  justify-content: center;
  align-items: center;
  gap: 8;
  display: 'inline-flex';
  }
  .Bigdepart h1{
    color: var(--Black-1000, #000);
font-family: "Noto Sans Arabic";
font-size: 64px;
font-style: normal;
font-weight: 600;
line-height: 50px; /* 78.125% */
  }
.secondSlideCont{height: 6vw;}
.BigsecondSlideCont{
  height: 15vw;

}
.secondSlideCont h1{
  color: var(--Black-1000, #000);
text-align: right;
font-family: "Noto Sans Arabic";
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: 50px; /* 138.889% */
height: 100%;
  overflow: hidden;
}
.BigsecondSlideCont h1{
  color: var(--Black-1000, #000);
  text-align: right;
  font-family: "Noto Sans Arabic";
  font-size: 75px;
  font-style: normal;
  font-weight: 400;
  line-height: 100px; /* 125% */
height: 100%;
  overflow: hidden;
}
.sliderFrame{
  width: 100% !important;
  height: 600px;
border: 1px solid #000;  /* Adjust the width and color as needed */
border-radius: 25px;
background-color: #EBF7F9;
width: 100%;
box-shadow: 9px 9px 0px black;
border: 1px black solid;
border-radius: 25px;
overflow: hidden;
justify-content: center;
align-items: center;
display: flex;
}
.BigsliderFrame{
  width: 100% !important;
  height: 1220px;
border: 1px solid #000;  /* Adjust the width and color as needed */
border-radius: 25px;
background-color: #FFFEED;
width: 100%;
box-shadow: 9px 9px 0px black;
border: 1px black solid;
border-radius: 25px;
overflow: hidden;
justify-content: center;
align-items: center;
display: flex;
}
.thirdSlideCont{
  display: flex;
  justify-content: center;
}
.mobileVersion{
  display: none ;
}
 
@media only screen and (max-width: 1024px) {
  .desktopVersion {
    display: none !important;
  }

  .mobileVersion{
    display: block;
  }
   
}
@media only screen and (min-width: 1024px) {
  .desktopVersion {
    display: block;
  }

  .mobileVersion{
    display: none !important;
  }
   
}

.fadein {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popupContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.85); /* semi-transparent background overlay */
  z-index: 2;
}